define("discourse/plugins/discourse-ratings/discourse/routes/admin-plugins-ratings", ["exports", "@ember/array", "@ember/object", "@ember/service", "rsvp", "discourse/routes/discourse", "discourse-i18n", "discourse/plugins/discourse-ratings/discourse/models/rating-object", "discourse/plugins/discourse-ratings/discourse/models/rating-type"], function (_exports, _array, _object, _service, _rsvp, _discourse, _discourseI18n, _ratingObject, _ratingType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noneType = "none";
  class AdminPluginsRatings extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model() {
      return _ratingType.default.all();
    }
    afterModel() {
      return (0, _rsvp.all)([this._typesFor("category"), this._typesFor("tag")]);
    }
    setupController(controller, model) {
      let ratingTypes = model || [];
      ratingTypes.unshift({
        type: noneType,
        name: (0, _discourseI18n.i18n)("admin.ratings.type.none_type"),
        isNone: true
      });
      controller.setProperties({
        ratingTypes: (0, _array.A)(ratingTypes.map(t => _ratingType.default.create(t))),
        categoryTypes: (0, _array.A)(this.categoryTypes),
        tagTypes: (0, _array.A)(this.tagTypes)
      });
    }
    _typesFor(object) {
      return _ratingObject.default.all(object).then(result => {
        this.set(`${object}Types`, result);
      });
    }
    refresh() {
      this.router.refresh();
    }
    static #_2 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
  }
  _exports.default = AdminPluginsRatings;
});