define("discourse/plugins/discourse-ratings/discourse/controllers/admin-plugins-ratings", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-ratings/discourse/models/rating-type"], function (_exports, _controller, _object, _computed, _service, _discourseI18n, _ratingType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsRatingsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "hasTypes", [(0, _computed.notEmpty)("ratingTypes")]))();
    #hasTypes = (() => (dt7948.i(this, "hasTypes"), void 0))();
    newType() {
      this.get("ratingTypes").pushObject(_ratingType.default.create({
        isNew: true,
        type: "",
        name: ""
      }));
    }
    static #_3 = (() => dt7948.n(this.prototype, "newType", [_object.action]))();
    addType(typeObj) {
      let data = {
        type: typeObj.type,
        name: typeObj.name
      };
      this.set("loading", true);
      _ratingType.default.add(data).then(result => {
        if (result.success) {
          this.send("refresh");
        } else {
          typeObj.set("hasError", true);
          this.set("loading", false);
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "addType", [_object.action]))();
    updateType(typeObj) {
      let data = {
        name: typeObj.name
      };
      this.set("loading", true);
      _ratingType.default.update(typeObj.type, data).then(result => {
        if (result.success) {
          this.send("refresh");
        } else {
          typeObj.set("hasError", true);
          this.set("loading", false);
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateType", [_object.action]))();
    destroyType(typeObj) {
      if (typeObj.isNew) {
        this.get("ratingTypes").removeObject(typeObj);
      } else {
        this.dialog.yesNoConfirm({
          message: (0, _discourseI18n.i18n)("admin.ratings.type.confirm_destroy"),
          didConfirm: () => {
            this.set("loading", true);
            _ratingType.default.destroy(typeObj.type).then(response => {
              if (response.success) {
                this.send("refresh");
              } else {
                typeObj.set("hasError", true);
                this.set("loading", false);
              }
            });
          }
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "destroyType", [_object.action]))();
  }
  _exports.default = AdminPluginsRatingsController;
});