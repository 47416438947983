define("discourse/plugins/discourse-ratings/discourse/components/rating-type", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
    {{#if this.type.isNew}}
      {{input
        value=this.type.type
        placeholder=(i18n "admin.ratings.type.type_placeholder")
      }}
    {{else}}
      {{this.type.type}}
    {{/if}}
  </td>
  
  <td>
    {{#if this.type.isNone}}
      {{i18n "admin.ratings.type.none_type_description"}}
    {{else}}
      {{input
        value=this.type.name
        placeholder=(i18n "admin.ratings.type.name_placeholder")
      }}
    {{/if}}
  </td>
  
  <td class="type-controls">
    {{#if this.showControls}}
      {{#if this.type.isNew}}
        {{d-button
          class="btn-primary"
          action=this.addType
          actionParam=this.type
          label="admin.ratings.type.add"
          icon="plus"
          disabled=this.addDisabled
        }}
      {{else}}
        {{d-button
          class="btn-primary"
          action=this.updateType
          actionParam=this.type
          label="admin.ratings.type.update"
          icon="save"
          disabled=this.updateDisabled
        }}
      {{/if}}
  
      {{d-button action=this.destroyType actionParam=this.type icon="times"}}
    {{/if}}
  </td>
  */
  {
    "id": "IYHVSvN4",
    "block": "[[[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"type\",\"isNew\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"value\",\"placeholder\"],[[30,0,[\"type\",\"type\"]],[28,[37,3],[\"admin.ratings.type.type_placeholder\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"type\",\"type\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"type\",\"isNone\"]],[[[1,\"    \"],[1,[28,[35,3],[\"admin.ratings.type.none_type_description\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"value\",\"placeholder\"],[[30,0,[\"type\",\"name\"]],[28,[37,3],[\"admin.ratings.type.name_placeholder\"],null]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"type-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"showControls\"]],[[[41,[30,0,[\"type\",\"isNew\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"class\",\"action\",\"actionParam\",\"label\",\"icon\",\"disabled\"],[\"btn-primary\",[30,0,[\"addType\"]],[30,0,[\"type\"]],\"admin.ratings.type.add\",\"plus\",[30,0,[\"addDisabled\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"class\",\"action\",\"actionParam\",\"label\",\"icon\",\"disabled\"],[\"btn-primary\",[30,0,[\"updateType\"]],[30,0,[\"type\"]],\"admin.ratings.type.update\",\"save\",[30,0,[\"updateDisabled\"]]]]]],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"action\",\"actionParam\",\"icon\"],[[30,0,[\"destroyType\"]],[30,0,[\"type\"]],\"times\"]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"td\",\"if\",\"input\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/rating-type.hbs",
    "isStrictMode": false
  });
  const minTypeLength = 2;
  const minNameLength = 2;
  const noneType = "none";
  const RatingType = dt7948.c(class RatingType extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "invalidType", [(0, _computed.lt)("type.type.length", minTypeLength)]))();
    #invalidType = (() => (dt7948.i(this, "invalidType"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "invalidName", [(0, _computed.lt)("type.name.length", minNameLength)]))();
    #invalidName = (() => (dt7948.i(this, "invalidName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "addDisabled", [(0, _computed.or)("invalidType", "invalidName")]))();
    #addDisabled = (() => (dt7948.i(this, "addDisabled"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "noneType", [(0, _computed.equal)("type.type", noneType)]))();
    #noneType = (() => (dt7948.i(this, "noneType"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showControls", [(0, _computed.not)("noneType")]))();
    #showControls = (() => (dt7948.i(this, "showControls"), void 0))();
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.set("currentName", this.type.name);
    }
    updateDisabled(invalidName, name, currentName) {
      return invalidName || name === currentName;
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateDisabled", [(0, _decorators.default)("invalidName", "type.name", "currentName")]))();
  }, [(0, _component2.classNameBindings)(":rating-type", ":admin-ratings-list-object", "hasError"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RatingType);
});