define("discourse/plugins/discourse-ratings/discourse/components/rating-destroy", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/lib/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating", "@ember/template-factory"], function (_exports, _component, _object, _component2, _decorators, _rating, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{category-chooser
    value=this.categoryId
    onChange=(action (mut this.categoryId))
  }}
  
  {{combo-box
    value=this.type
    content=this.ratingTypes
    valueProperty="type"
    onChange=(action (mut this.type))
    options=(hash none="admin.ratings.type.select")
  }}
  
  {{d-button
    action=(action this.destroyRatings)
    label="admin.ratings.destroy.btn"
    disabled=this.destroyDisabled
  }}
  
  {{#if this.startingDestroy}}
    {{loading-spinner size="small"}}
  {{/if}}
  
  {{#if this.destroyMessage}}
    <div class="action-message">
      {{i18n this.destroyMessage}}
    </div>
  {{/if}}
  */
  {
    "id": "th1jwsLs",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"onChange\"],[[30,0,[\"categoryId\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"categoryId\"]]],null]],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"value\",\"content\",\"valueProperty\",\"onChange\",\"options\"],[[30,0,[\"type\"]],[30,0,[\"ratingTypes\"]],\"type\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"type\"]]],null]],null],[28,[37,4],null,[[\"none\"],[\"admin.ratings.type.select\"]]]]]]],[1,\"\\n\\n\"],[1,[28,[35,5],null,[[\"action\",\"label\",\"disabled\"],[[28,[37,1],[[30,0],[30,0,[\"destroyRatings\"]]],null],\"admin.ratings.destroy.btn\",[30,0,[\"destroyDisabled\"]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"startingDestroy\"]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"destroyMessage\"]],[[[1,\"  \"],[10,0],[14,0,\"action-message\"],[12],[1,\"\\n    \"],[1,[28,[35,9],[[30,0,[\"destroyMessage\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"category-chooser\",\"action\",\"mut\",\"combo-box\",\"hash\",\"d-button\",\"if\",\"loading-spinner\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/components/rating-destroy.hbs",
    "isStrictMode": false
  });
  const RatingDestroy = dt7948.c(class RatingDestroy extends _component.default {
    destroyDisabled(categoryId, type) {
      return [categoryId, type].any(i => !i);
    }
    static #_ = (() => dt7948.n(this.prototype, "destroyDisabled", [(0, _decorators.default)("categoryId", "type")]))();
    destroyRatings() {
      let data = {
        category_id: this.categoryId
      };
      this.set("startingDestroy", true);
      _rating.default.destroy(this.type, data).then(result => {
        if (result.success) {
          this.set("destroyMessage", "admin.ratings.destroy.started");
        } else {
          this.set("destroyMessage", "admin.ratings.error.destroy_failed_to_start");
        }
      }).finally(() => this.set("startingDestroy", false));
    }
    static #_2 = (() => dt7948.n(this.prototype, "destroyRatings", [_object.action]))();
  }, [(0, _component2.classNames)("admin-ratings-destroy", "rating-action")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RatingDestroy);
});